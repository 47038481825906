<template>
  <div class="recommendation">
    <HomeHeader />
    <!-- <PromoBanner :height="headerHeight" :src="promoBannerSrc" /> -->
    <div class="img">
      <img src="@/assets/images/pc/Inside/Inside-pc-4.png" alt="" />
    </div>
  </div>
</template>

<script>
import HomeHeader from "@/components/pc/HomeHeader";

import elementHeight from "@/mixins/pc/elementHeight";

export default {
  name: "Recommendation",
  mixins: [elementHeight],

  components: {
    HomeHeader,
  },
};
</script>

<style lang="less" scoped>
.recommendation {
  font-size: 16px;

  img {
    width: 100%;
  }
}
</style>
